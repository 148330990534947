import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Hero from "../components/Hero";
import SectionDivisor from "../components/SectionDivisor";
import ContainerSection from "../components/ContainerSection";
import HeroCta from "../components/HeroCta";
import Brands from "../components/Brands";
import CardStats from "../components/CardStats";

import SectionTitle from "../components/SectionTitle";
import FeatureWithImage from "../components/FeatureWithImage";
import FeatureWithImagePNG from "../components/FeatureWithImagePNG";
import FeatureWithImageCta from "../components/FeatureWithImageCta";
import Card from "../components/Card";
import Phrase from "../components/Phrase";
import CommandPrompt from "../components/CommandPrompt";

import Comotrabajamossolucionesit from "../components/Comotrabajamossolucionesit";

import imageHero from "../images/monitoreo.svg";

// import AbonosImage from '../images/abonos2.svg';
import SeguridadImage from "../images/security.svg";
import ProyectoImage from "../images/proyectos.svg";
import CapacitacionesImage from "../images/capacitacion.svg";
import MonitoreoImage from "../images/monitoreo-it.svg";
import BackupImage from "../images/backupimage.svg";
import ImagenFrase from "../images/frase.svg";
import CloudImage from "../images/cloud.svg";
import MantenimientoImage from "../images/mantenimiento.svg";
import ConsultoriaImage from "../images/consultoria.svg";
import GerenciamientoImage from "../images/gerenciamiento.svg";
import AuditoriaImage from "../images/auditorias.svg";
import VirtualizacionImage from "../images/virtualizacion.svg";

import Soporteit from "../images/soporteit.svg";
import Gerenciamientoit from "../images/gerenciamientoit.svg";
import Administracionit from "../images/administracionit.svg";
import Consultoriait from "../images/consultoriait.svg";
import SoporteImage from "../images/soporteimagen.svg";

// import triangle_yellow from '../images/triangle_yellow.svg';
// import triangle_orange from '../images/triangle_orange.svg';
// import triangle_white from '../images/triangle_white.svg';

import Proyectos from "../images/proyecto-stat.svg";
import Certificaciones from "../images/certificaciones.svg";
import Clientes from "../images/clientes.svg";
import Productividad from "../images/productividad.svg";
import Capacitaciones from "../images/capacitaciones.svg";

function IndexPage() {
  const { AbonosImage, partners } = useStaticQuery(graphql`
    query {
      AbonosImage: file(relativePath: { eq: "abonos.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      partners: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "partners" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);


  const partnersImg = partners.edges.map(
    (img) => img.node.childImageSharp.fluid.src
  );
  partnersImg.sort();

  return (
    <Layout>
      <Hero
        title={"SOLUCIONES IT"}
        subtitle={"Servicios integrales para potenciar tu negocio"}
        header={"¿A qué nos dedicamos?"}
        image={imageHero}
      />

      <SectionDivisor type={1} />

      <ContainerSection type={6}>
        <SectionTitle title={"Conozca nuestros servicios"} classTitle="mt-16" />

        <FeatureWithImagePNG
          title={"Abonos "}
          description={
            "Solución de tecnología integral para PYMES, organizaciones sin fines de lucro, entidades educativas y grandes empresas."
          }
          description2={
            "Adaptable a cada necesidad, un servicio proactivo que puede ensamblar el soporte, mantenimiento y administración de su infraestructura, la gestión y ejecución de proyectos como así también el asesoramiento tecnológico."
          }
          description3={
            "Incorpore a su organización consultores expertos, certificados en diversas tecnologías, actualizados en las mejores prácticas del mercado, quienes brindarán rápida respuesta ante incidentes y asesoramiento tecnológico que potenciará su negocio."
          }
          img={AbonosImage}
          reverse
        />

        <div className="mt-10 w-full flex flex-col sm:w-full md:w-full sm:flex-row justify-center md:justify-around">
          <div
            data-sal="slide-down"
            data-sal-delay="100"
            data-sal-easing="ease"
            className="w-full flex flex-row justify-around"
            once
          >
            <CardStats
              title={"Soporte IT"}
              classesTitle={
                "text-lg lg:text-xl text-blue-600 hover:text-orange-500"
              }
              classesCard={"border-b-4 border-gray-400 hover:border-blue-500"}
              image={Soporteit}
              ctaUrl={"/soluciones-it/#soporteit"}
            />

            <CardStats
              title={"Administración IT"}
              classesTitle={
                "text-lg lg:text-xl text-blue-600 hover:text-orange-500"
              }
              classesCard={"border-b-4 border-gray-400 hover:border-blue-500"}
              image={Administracionit}
              ctaUrl={"/soluciones-it/#administracionit"}
            />
          </div>

          <div
            data-sal="zoom-in"
            data-sal-delay="100"
            data-sal-duration="700"
            data-sal-easing="ease"
            className="mt-16 sm:mt-0 w-full flex flex-row justify-around mb-10 sm:mb-0"
            once
          >
            <CardStats
              title={"Consultoría IT"}
              classesTitle={
                "text-lg lg:text-xl text-blue-600 hover:text-orange-500"
              }
              classesCard={"border-b-4 border-gray-400 hover:border-blue-500"}
              image={Consultoriait}
              ctaUrl={"/soluciones-it/#consultoriait"}
            />

            <CardStats
              title={"Gerenciamiento IT"}
              classesTitle={
                "text-lg lg:text-xl text-blue-600 hover:text-orange-500"
              }
              classesCard={"border-b-4 border-gray-400 hover:border-blue-500"}
              image={Gerenciamientoit}
              ctaUrl={"/soluciones-it/#gerenciamientoit"}
            />
          </div>
        </div>

        <FeatureWithImageCta
          title={"Monitoreo Integral Proactivo"}
          description={
            "Monitoreamos remotamente toda su infraestructura tecnológica, para anticiparnos proactivamente a cualquier inconveniente, optimizar la experiencia de los usuarios y tomar decisiones alineadas al negocio."
          }
          description2={
            "Los diferentes indicadores permiten evaluar si todo se encuentra funcionando según lo planificado. Los informes resultantes contribuyen al desarrollo organizacional."
          }
          // description2={'Organizamos tu parque informático y reducimos costos de infraestructura, consumo y administración.'}
          img={MonitoreoImage}
          classCard={"gradient-card"}
          classTitle={"text-white"}
          classDescription={"text-gray-300"}
          // ctaText={'Solicitar asistencia'}
          // ctaUrl={'/contacto#contactForm'}
        />

        <Phrase
          title={
            "Servicios de calidad y resultados de excelencia para llevar tu organización al siguiente nivel"
          }
          img={ImagenFrase}
        />

        <FeatureWithImage
          title={"Soporte IT"}
          description={
            "Diseñamos planes de soporte a la medida de cada necesidad."
          }
          description2={
            "Llevamos adelante el mantenimiento preventivo y correctivo de sus equipos informáticos focalizando en la calidad y mejora continua."
          }
          description3={
            "Brindamos asistencia a usuarios en forma remota y presencial a través de un equipo multidisciplinario con conocimientos certificados, de gran calidez humana y responsabilidad."
          }
          img={SoporteImage}
          id={"soporteit"}
          reverse
        />
      </ContainerSection>

      <div className="w-full bg-white py-2 md:py-3"></div>

      <Comotrabajamossolucionesit />

      {/* <div className="w-full flex justify-center py-16 bg-blue-700">

        <div className="flex flex-col md:w-5/6 justify-center items-center ">

          <h1 className="text-2xl md:text-4xl text-center text-white uppercase font-black mb-5">Así trabajamos:</h1> */}
      {/* <p className="text-xl text-blue-700 font-bold mb-10">Así trabajamos...</p> */}

      {/* <div className="flex flex-col md:flex-row w-full justify-around items-center md:items-start">

            <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10">
              <div className="h-16 w-16 flex justify-center items-center rounded-full text-2xl text-white font-black mb-6 border-solid border-4 border-yellow-500 shadow-lg">1</div>
              <div className=" flex justify-center items-center text-2xl font-black text-white">ANALIZAMOS</div>
              <img className="h-6 w-6 mb-3" src={triangle_yellow}></img>
              <p className="text-white text-center">Analizamos su necesidad, evaluamos qué hacer y cómo hacerlo, planificamos, dimensionamos, medimos viabilidad, riesgos y factores de éxito, proyectamos, diseñamos procedimientos y cotizamos.</p>
            </div>

            <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10">
            <div className="h-16 w-16 flex justify-center items-center rounded-full text-2xl text-white font-black mb-6 border-solid border-4 border-orange-600 shadow-lg">2</div>
            <div className=" flex justify-center items-center text-2xl font-black text-white">EJECUTAMOS</div>
              <img className="h-6 w-6 mb-3" src={triangle_orange}></img>
              <p className="text-white text-center">Ejecutamos lo diseñado en el paso previo. Un equipo de especialistas llevará adelante la implementación según la planificación, documentando procedimientos hasta el cierre, asegurando el cumplimiento de todas las metas definidas.</p>
            </div>

            <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10">
            <div className="h-16 w-16 flex justify-center items-center rounded-full text-2xl text-white font-black mb-6 border-solid border-4 border-white shadow-lg">3</div>
            <div className=" flex justify-center items-center text-2xl font-black text-white">VALIDAMOS</div>
              <img className="h-6 w-6 mb-3" src={triangle_white}></img>
              <p className="text-white text-center">Un ejecutivo de cuentas se encargará de validar los resultados junto a usted y sus colaboradores, asegurando su conformidad y garantizando la calidad del trabajo realizado.</p>
            </div>

            </div>

          </div>
        </div> */}

      <ContainerSection type={6}>
        <FeatureWithImage
          title={"Gestión de Infraestructura"}
          description={
            "Construimos, desarrollamos y administramos la infraestructura tecnológica de nuestros clientes de forma metódica y práctica. Nos focalizamos en garantizar la optimización de recursos, su performance, escalabilidad,  la seguridad y su disponibilidad operativa."
          }
          description2={
            "La optimización de procesos operativos y el avance hacia una transformación digital requiere contar con una infraestructura capaz de operar tecnologías modernas y en constante evolución."
          }
          description3={
            "La administración correcta de la infraestructura, con la diversidad de tecnologías que la integran, desempeña un papel central para lograr la mejor experiencia de los usuarios y es cimiento necesario para un negocio sostenible."
          }
          img={MantenimientoImage}
          id={"administracionit"}
          reverse
        />

        {[
          {
            title: "Seguridad Informática",
            description:
              "Contamos con personal calificado con alto nivel de experiencia y las certificaciones más importantes del mercado en materia de seguridad informática.",
            description2:
              "Ejecutamos las mejores prácticas para la protección contra amenazas, detección de inconvenientes y respuesta ante ciberataques.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: SeguridadImage,
          },
          {
            title: "Backup y Disaster Recovery",
            description:
              "Diseñamos e implementamos estrategias para reaccionar rápidamente ante desastres. Desarrollamos un plan de acción que asegure la continuidad de su negocio, reduciendo el tiempo de caída de servicios críticos y evitando pérdida de información.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: BackupImage,
          },
        ].map((card) => (
          <Card
            key={card.title}
            title={card.title}
            description={card.description}
            description2={card.description2}
            id={card.id}
            // cta={card.cta}
            // ctaUrl={card.ctaUrl}
            img={card.img}
          />
        ))}


            <div className="w-full sm:w-5/6 flex flex-col sm:w-full md:w-full sm:flex-row justify-around items-center md:items-start border-t border-b">
              <CardStats
                preTitle={"+"}
                title={2306}
                subtitle={"Proyectos"}
                description={"realizados"}
                image={Proyectos}
              />

              <CardStats
                postTitle={"%"}
                title={68}
                subtitle={"Super clientes"}
                description={
                  "se mantienen junto a nosotros hace más de 10 años"
                }
                image={Clientes}
              />

              <CardStats
                preTitle={"+"}
                title={602}
                subtitle={"Capacitaciones"}
                description={"brindadas"}
                image={Capacitaciones}
              />

              <CardStats
                preTitle={"+"}
                title={20}
                postTitle={"%"}
                subtitle={"Productividad"}
                description={
                  "gracias a la optimización de recursos y mejora de procesos"
                }
                image={Productividad}
              />

              <CardStats
                preTitle={"+"}
                title={40}
                subtitle={"Certificaciones"}
                description={"recibidas"}
                image={Certificaciones}
              />
            </div>

        <FeatureWithImage
          title={"Consultoría IT"}
          description={
            "Analizamos de forma precisa la situación particular de cada cliente, diseñando estrategias que mejoren su infraestructura tecnológica, tanto para prevenir desastres como para garantizar el uso eficiente de los recursos informáticos, reduciendo costos, mejorando su inversión y potenciando sus negocios."
          }
          description2={
            "Nuestro equipo de consultores cuenta  con certificaciones que avalan su aptitud para diseñar, implementar, asesorar y administrar tecnologías de vanguardia con un alto grado de conocimiento."
          }
          img={ConsultoriaImage}
          id={"consultoriait"}
          reverse
        />

        {[
          {
            title: "Servicios CLOUD",
            description:
              "Ayudamos a las organizaciones a modernizar sus servicios y aplicaciones migrando de forma eficiente hacia las mejores infraestructuras cloud del mundo.",
            description2:
              "La adopción de servicios en la nube aporta ventajas inmediatas como la reducción de costos, el aumento de seguridad, disponibilidad y flexibilidad.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: CloudImage,
          },
          {
            title: "Proyectos, Implementaciones y Migraciones",
            description:
              "Diseñamos, ejecutamos y gestionamos proyectos de infraestructura utilizando metodología profesional, aportando nuestro conocimiento y experiencia.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: ProyectoImage,
          },
          {
            title: "Virtualización",
            description:
              "Planificación e implementación de proyectos de virtualización basados en tecnologías de vanguardia permitiendo optimizar la utilización de recursos. Organizamos tu parque informático y reducimos costos de infraestructura, consumo y administración.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: VirtualizacionImage,
          },
        ].map((card) => (
          <Card
            key={card.title}
            title={card.title}
            description={card.description}
            description2={card.description2}
            id={card.id}
            img={card.img}
          />
        ))}

        <CommandPrompt />

        <div className=" pt-10 w-full h-2 border-b"></div>

        <FeatureWithImage
          title={"Gerenciamiento IT"}
          description={
            "Profesionalizamos el área de tecnología de las organizaciones, alineando sus objetivos, articulando normas y procedimientos informáticos."
          }
          description2={
            "Mantenemos un proceso de mejora continua sobre la calidad de los servicios prestados. Analizamos, medimos, controlamos y planificamos acciones de tal manera que superen los resultados previstos."
          }
          img={GerenciamientoImage}
          id={"gerenciamientoit"}
          reverse
        />
        <div className=" mb-10 w-full h-2 border-b"></div>

        {[
          {
            title: "Capacitaciónes Personalizadas",
            description:
              "Nuestros consultores especializados dirigen cursos y workshops a la medida de cada organización y sus integrantes. Diseñados para distintos niveles de auditorios, transmitiendo su experiencia y conocimiento sobre las tecnologías expuestas.",
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: CapacitacionesImage,
          },
          {
            title: "Auditorias",
            description:
              "Verificamos procesos informáticos, analizamos activos y cumplimiento de políticas que resguarden confidencialidad, disponibilidad e integridad. Identificamos inconvenientes, sus causas y proponemos soluciones.",
            // description: 'Verificamos que los procesos informáticos se ejecuten según lo planificado, que los activos tecnológicos se encuentren correctamente protegidos y se cumplan las políticas establecidas para la confidencialidad disponibilidad e integridad de los sistemas de información.',
            // description2: 'Identificamos las causas y soluciones a los inconvenientes detectados que afectan la operatoria y a las estrategias del negocio.',
            cta: "Consultar",
            ctaUrl: "/contacto#contactForm",
            img: AuditoriaImage,
          },
        ].map((card) => (
          <Card
            key={card.title}
            title={card.title}
            description={card.description}
            description2={card.description2}
            id={card.id}
            // cta={card.cta}
            // ctaUrl={card.ctaUrl}
            img={card.img}
          />
        ))}
      </ContainerSection>

      <Brands
        title="PARTNERS"
        logos={partnersImg.map((partner) => ({
          classesBrand: "h-auto lg:h-16",
          img: partner,
        }))}
        bgColorTailwind="bg-white"
        quantityItems={6}
        autoplay
      />

      <SectionDivisor color1={"#FFFFFF"} color2={"#FFFFFF"} type={2} />

      <HeroCta
        cta={"Buscás otro servicio?"}
        message={"Consultános, estamos para ayudarte!"}
        actionText={"Consultar otros servicios"}
        url={"/contacto#contactForm"}
      />
    </Layout>
  );
}

export default IndexPage;
