import PropTypes from 'prop-types';
import React from 'react';
import Image from 'gatsby-image';


const FeatureWithImagePNG = ({ title, description, description2, description3, img, reverse, id, separator, classImg='w-5/6' }) => {
    return (<>
        <div id={id} className={`flex flex-wrap justify-center items-center mt-5 ${reverse ? 'flex-row-reverse' : ''} `}>
            
            <div 
                 data-sal="slide-down"
                 data-sal-delay="100"
                 data-sal-duration="700"
                 data-sal-easing="ease"
            className="w-full sm:w-1/2">
                <div className="w-full lg:w-5/6 pb-6 md:pb-0 flex justify-center">
                    {/* <img className={`${classImg} h-auto`} src={img} /> */}
                    <Image
                        className={`${classImg} h-auto`}
                        oading="eager"
                        imgStyle={{ objectFit: 'cover' }}
                        fluid={img.sharp.fluid} />
                </div>
            </div>
            
            <div className="sm:w-1/2 p-6 flex flex-col align-middle justify-center">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">{title}</h3>
                <p className="text-gray-600 text-lg mb-4">{description}
                <br />
                <br />
                    {description2} 
                    <br/> 
                    <br />
                    {description3}
                </p>
            </div>           
            {separator && <div className="flex justify-center border-dotted border-b-2 border-gray-500 w-5/6"> </div> }
        </div>
    </>);
}

FeatureWithImagePNG.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    description2: PropTypes.string,
    description3: PropTypes.string,
    classImg: PropTypes.string,
    id: PropTypes.string,
    img: PropTypes.object.isRequired,
    reverse: PropTypes.bool,
    separator: PropTypes.bool
}

export default FeatureWithImagePNG;