import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image'


    // import paso1soluciones from '../images/paso1soluciones.svg';
    // import paso2soluciones from '../images/paso2soluciones.svg';
    // import paso3soluciones from '../images/paso3soluciones.svg';

import procesosfeedback from '../images/procesosfeedback.svg';

const Comotrabajamossolucionesit = () => {


    const { imagenFondo } = useStaticQuery(graphql`
        query {
            imagenFondo: file(relativePath: { eq: "nubes.png" }) {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
        }
        `);


    return (
    <>

        <BackgroundImage Tag="section"
                           className={'w-full flex-row justify-center pt-8 pb-16 bg-gray-300'}
                           fluid={imagenFondo.childImageSharp.fluid}
                        //    backgroundColor={`#040e18`}
          >

            <h1 className="text-2xl md:text-3xl text-center text-white uppercase font-black azuloscurofeedback">Etapas del abono:</h1>
                <div className="flex justify-center w-full mb-5 border-blue-500">
                    <div className="w-2/12 border"></div>
                </div>

            <div className="flex flex-row w-full justify-center">
                <div className="flex flex-col md:flex-row w-full justify-center flex-col-reverse">
                    
                    <div className="flex justify-center w-full md:w-5/12">
                        <img src={procesosfeedback} />
                    </div>
                </div>
            </div>
          </BackgroundImage>   
                
    </>);
}

// Comotrabajamossolucionesit.propTypes = {
   
// }

export default Comotrabajamossolucionesit;