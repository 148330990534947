import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import CountUp from "react-countup";

const CardStats = ({
  title,
  subtitle,
  description,
  ctaUrl,
  classesTitle = "text-4xl",
  classesCard = "hover:-translate-y-1 hover:scale-110",
  image,
  preTitle = "",
  postTitle = "",
}) => {
  return (
    <>
      <div
        className={`flex flex-col justify-center px-4 sm:py-4 mt-10 lg:mt-5 rounded lg:w-64 transition duration-500 ease-in-out transform ${classesCard}`}
      >
        {ctaUrl ? (
          <Link to={ctaUrl ? ctaUrl : "#"}>
            <div>
              <div className="flex items-center justify-center">
                <img
                  src={image}
                  className="-mt-24 mb-3 h-10 lg:h-16 fill-current"
                />
              </div>
            </div>
            <p
              className={`font-semibold text-center text-gray-800 ${classesTitle}`}
            >
              {title}
            </p>
            <p className="text-xl text-center text-gray-500">{description}</p>
          </Link>
        ) : (
          <>
            <div>
              <div className="flex items-center justify-center">
                <img src={image} className="mb-3 w-20 h-20 fill-current" />
              </div>
            </div>
            <p
              className={`font-semibold text-center text-gray-800 ${classesTitle}`}
            >
              {preTitle}
              {/* {title} */}

            <CountUp start={0} end={title} />

              {/* <VisibilitySensor
                onChange={this.onVisibilityChange}
                offset={{
                  top: 10,
                }}
                delayedCall
              >
                <CountUp
                  className={style.countup}
                  decimals={1}
                  start={0}
                  end={this.state.didViewCountUp ? 25 : 0}
                  suffix=" %"
                  duration={3}
                />
              </VisibilitySensor> */}

              {postTitle}
            </p>
            <p className={`font-semibold text-xl text-center text-blue-700`}>
              {subtitle}
            </p>
            <p className="text-md text-center text-gray-600">{description}</p>
          </>
        )}
      </div>
    </>
  );
};

CardStats.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  ctaUrl: PropTypes.string,
  classesTitle: PropTypes.string,
  classesCard: PropTypes.string,
  preTitle: PropTypes.string,
  postTitle: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default CardStats;
